﻿
export class CanvasWrapper {
    private readonly canvas: HTMLCanvasElement;
    private readonly context: CanvasRenderingContext2D;
    private paint: boolean;

    private clickX: number[] = [];
    private clickY: number[] = [];
    private clickDrag: boolean[] = [];

    constructor(canvasId: string) {
        const canvas = document.getElementById(canvasId) as
            HTMLCanvasElement;

        if (canvas === null)
            throw new Error("Can't find canvas element");

        const context = canvas.getContext("2d");
        if (context === null)
            throw new Error("Can't find canvas 2d context");

        context.lineCap = "round";
        context.lineJoin = "round";
        context.strokeStyle = "var(--mud-palette-text-primary)";
        context.lineWidth = 1;

        this.canvas = canvas;
        this.context = context;
        this.paint = false;

        this.redraw();
        this.createUserEvents();
    }

    private createUserEvents = () => {
        const canvas = this.canvas;

        canvas.addEventListener("mousedown", this.pressEventHandler);
        canvas.addEventListener("mousemove", this.dragEventHandler);
        canvas.addEventListener("mouseup", this.releaseEventHandler);
        canvas.addEventListener("mouseout", this.cancelEventHandler);

        canvas.addEventListener("touchstart", this.pressEventHandler);
        canvas.addEventListener("touchmove", this.dragEventHandler);
        canvas.addEventListener("touchend", this.releaseEventHandler);
        canvas.addEventListener("touchcancel", this.cancelEventHandler);
    }

    private redraw = () => {
        const clickX = this.clickX;
        const context = this.context;
        const clickDrag = this.clickDrag;
        const clickY = this.clickY;

        for (let i = 0; i < clickX.length; ++i) {
            context.beginPath();
            if (clickDrag[i] && i) {
                context.moveTo(clickX[i - 1], clickY[i - 1]);
            } else {
                context.moveTo(clickX[i] - 1, clickY[i]);
            }

            context.lineTo(clickX[i], clickY[i]);
            context.stroke();
        }
        context.closePath();
    }

    private addClick = (x: number, y: number, dragging: boolean) => {
        this.clickX.push(x);
        this.clickY.push(y);
        this.clickDrag.push(dragging);
    }

    private releaseEventHandler = () => {
        this.paint = false;
        this.redraw();
    }

    private cancelEventHandler = () => {
        this.paint = false;
    }

    private pressEventHandler = (e: MouseEvent | TouchEvent) => {
        let mouseX = (e as TouchEvent).changedTouches ?
            (e as TouchEvent).changedTouches[0].pageX :
            (e as MouseEvent).pageX;
        let mouseY = (e as TouchEvent).changedTouches ?
            (e as TouchEvent).changedTouches[0].pageY :
            (e as MouseEvent).pageY;

        const absoluteCanvasPosition = this.canvas.getBoundingClientRect();

        mouseX -= absoluteCanvasPosition.x;
        mouseY -= absoluteCanvasPosition.y;

        this.paint = true;
        this.addClick(mouseX, mouseY, false);
        this.redraw();
    }

    private dragEventHandler = (e: MouseEvent | TouchEvent) => {
        let mouseX = (e as TouchEvent).changedTouches ?
            (e as TouchEvent).changedTouches[0].pageX :
            (e as MouseEvent).pageX;
        let mouseY = (e as TouchEvent).changedTouches ?
            (e as TouchEvent).changedTouches[0].pageY :
            (e as MouseEvent).pageY;

        const absoluteCanvasPosition = this.canvas.getBoundingClientRect();

        mouseX -= absoluteCanvasPosition.x;
        mouseY -= absoluteCanvasPosition.y;

        if (this.paint) {
            this.addClick(mouseX, mouseY, true);
            this.redraw();
        }

        e.preventDefault();
    }

    public toBase64Png = (): string => {
        return this.canvas.toDataURL("image/png");
    }

    public clearCanvas = () => {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.clickX = [];
        this.clickY = [];
        this.clickDrag = [];
    }
}

const CanvasWrapperInstances: {  [key: string]: CanvasWrapper } = { };

export function addCanvasWrapperInstance(canvasId: string) {
    CanvasWrapperInstances[canvasId] = new CanvasWrapper(canvasId);
}

export function getCanvasImage(canvasId: string): string {
    const canvas = CanvasWrapperInstances[canvasId];

    if (canvas !== undefined) {
        return canvas.toBase64Png();
    }

    return "";
}

export function clearCanvas(canvasId: string): void {
    const canvas = CanvasWrapperInstances[canvasId];

    if (canvas !== undefined) {
        canvas.clearCanvas();
    }
}

export function removeCanvasWrapperInstance(canvasId: string) {
    if (CanvasWrapperInstances[canvasId] !== undefined)
        delete CanvasWrapperInstances[canvasId];
}

export default {
    addCanvasWrapperInstance,
    getCanvasImage,
    clearCanvas,
    removeCanvasWrapperInstance,
}
