
export function ScrollToBottom(elementName: string) {
    const element = document.getElementById(elementName);

    if (element === null)
        throw new Error(`HTML Element not found with id ${elementName}`);

    element.scrollTop = element.scrollHeight - element.clientHeight;
}

export default ScrollToBottom;
