// interface IBrowserSize {
//     height: number;
//     width: number;
// }

// function getWindowSize(): IBrowserSize {
//     const height: number = window.innerHeight;
//     const width: number = window.innerWidth;

//     return {
//         height: height,
//         width: width,
//     };
// }

// export function getWindowSizeJson(): string {
//     return JSON.stringify(getWindowSize());
// }

export function getBrowserLanguages(): Promise<readonly string[]> {
    return Promise.resolve(navigator.languages);
};

// function notifyWindowResize(dotNetReference: any) {
//     const windowSize = getWindowSize();

//     dotNetReference.invokeMethodAsync(
//         "SetBrowserWindowDimensions",
//         windowSize.height,
//         windowSize.width
//     );
// }

// export function subscribeWindowResizeEvent(dotNetReference: any): void {
//     window.addEventListener('resize', () => {
//         notifyWindowResize(dotNetReference);
//     });

//     notifyWindowResize(dotNetReference);
// }

export default {
    // getWindowSizeJson,
    getBrowserLanguages,
    // notifyWindowResize,
    // subscribeWindowResizeEvent,
}
