﻿import IResult from "../Result";
import {NotificationsConfigResponse, NotificationSubscription} from "./types";

const ErrorNotSupported = "Notifications not supported";
const ErrorPermissionDenied = "Registration failed - permission denied";
const SubscribeSuccess = "Subscribed to push notifications with success";
const ErrorNotSubscribed = "Not subscribed to push notifications";

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    // https://stackoverflow.com/a/9458996
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const bytesLen = bytes.byteLength;

    for (let i = 0; i < bytesLen; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
}

const subscribe = async (worker: ServiceWorkerRegistration, notificationsConfig: NotificationsConfigResponse): Promise<PushSubscription> => {
    try {
        return await worker.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: notificationsConfig.publicKey
        });
    } catch (error: any) {
        if (error.name === 'NotAllowedError') {
            throw new Error(ErrorPermissionDenied);
        }

        throw error;
    }
}

const getServiceWorker = async(): Promise<ServiceWorkerRegistration> => {
    if (navigator.serviceWorker === undefined)
        throw new Error(ErrorNotSupported);
        
    const worker = await navigator.serviceWorker.getRegistration();

    if (worker === undefined || worker.pushManager === undefined)
        throw new Error(ErrorNotSupported);

    return worker;
}

const formatSubscription = (subscription: PushSubscription): NotificationSubscription => {
    return {
        url: subscription.endpoint,
        p256dh: arrayBufferToBase64(subscription.getKey('p256dh') ?? new ArrayBuffer(0)),
        auth: arrayBufferToBase64(subscription.getKey('auth') ?? new ArrayBuffer(0)),
    };
}

const formatException = <TResult>(e: Error): IResult<TResult> => {
    const response: IResult<TResult> = {
        succeeded: false,
    }

    if (e.message === undefined) {
        console.log(e);

        response.messages = [
            e.toString(),
        ];

        return response;
    }

    response.messages = [
        e.message,
    ];

    return response;
}


const requestSubscription = async (notificationsConfig: NotificationsConfigResponse): Promise<IResult<NotificationSubscription>> => {
    try {
        const worker = await getServiceWorker();
        const subscription = (await worker.pushManager.getSubscription()) ?? await subscribe(worker, notificationsConfig);

        return {
            succeeded: true,
            messages: [SubscribeSuccess],
            data: formatSubscription(subscription),
        };
        
    } catch (e: any) {
        return formatException(e);
    }
}

const getExistingSubscription = async (): Promise<IResult<NotificationSubscription>> => {
    try {
        const worker = await getServiceWorker();
        const subscription = await worker.pushManager.getSubscription();

        return {
            succeeded: true,
            data: subscription !== null ? formatSubscription(subscription) : null,
        };
    } catch (e: any) {
        return formatException(e);
    }
}

const requestUnsubscription = async (): Promise<IResult<NotificationSubscription>> => {
    try {
        const worker = await getServiceWorker();
        const subscription = await worker.pushManager.getSubscription();

        if (subscription === null)
            throw new Error(ErrorNotSubscribed);

        await subscription.unsubscribe();

        return {
            succeeded: true,
            data: formatSubscription(subscription),
        };
    } catch (e: any) {
        return formatException(e);
    }
}

export default {
    requestSubscription,
    getExistingSubscription,
    requestUnsubscription,
};
