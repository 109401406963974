﻿
export const set = (key: string, value: string, expiresAfterMinutes?: number): void => {
    let cookie = `${key}=${value};`;

    if (expiresAfterMinutes !== undefined && expiresAfterMinutes !== null) {
        const exprirationDate = new Date();
        exprirationDate.setTime(exprirationDate.getTime() + (expiresAfterMinutes * 60 * 1000));
        cookie = `${cookie}expires=${exprirationDate.toISOString()}`;
    }

    document.cookie = cookie;
}

export const get = (key: string): string | undefined => {
    const fullKey = `${key}=`;
    const cookies = document.cookie.split(';');
    
    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(fullKey) == 0) {
            return cookie.substring(fullKey.length, cookie.length);
        }
    }

    return undefined;
}

export const remove = (key: string): void => {
    set(key, "", -1)
}

// export function createCookie(name: string, value: string, minutes: number, path: string) {
//     let expires = "";

//     if (minutes) {
//         const date = new Date();

//         date.setTime(date.getTime() + (minutes * 60 * 1000));
//         expires = `expires=${date.toUTCString()};`;
//     }

//     document.cookie = `${name}=${value}; ${expires} path=${path}`;
// }

export default {
    get,
    set,
    remove,
}
