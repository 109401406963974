
export interface DownloadOptionType {
    byteArray: string,
    mimeType: string,
    fileName: string,
}

export async function Download(options: DownloadOptionType) {
    const fileUrl = `data:${options.mimeType};base64,${options.byteArray}`;
    await fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
            const link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob.slice(0, blob.size, options.mimeType));
            link.download = options.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
}

export default Download;